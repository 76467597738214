//App.js
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import "./App.css"; 

import Home from "./Home.js";
import About2 from "./About2.js";
import Contact from "./Contact.js";
import Services from "./Services.js";
import Programs from "./Programs.js";
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/About2" element={<About2/>}/> 
        <Route path="/Services" element={<Services/>}/> 
        <Route path="/Programs" element={<Programs/>}/> 
        <Route path="/Contact" element={<Contact/>}/> 
     
     </Routes> 
     </BrowserRouter>
  
  );
};

export default App;