import React, { useState } from "react";
import "./Request.css";
import bgImage from "./images/bg_5.jpg";

const Request = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "", // Keep this empty so the user can input their own email
    course: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://formspree.io/f/xpwaepgj", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email, // Use the email the user inputs
          course: formData.course,
          phone: formData.phone,
          message: formData.message,
        }),
      });

      if (response.ok) {
        alert("Form submitted successfully!");
        setFormData({
          firstName: "",
          lastName: "",
          email: "indiashometuition@gmail.com", // Reset user input
          course: "",
          phone: "",
          message: "",
        });
      } else {
        alert("There was an error submitting the form.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Submission failed, please try again.");
    }
  };

  return (
    <section
      className="ftco-section ftco-consult ftco-no-pt ftco-no-pb"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
      data-stellar-background-ratio="0.5"
    >
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-md-6 py-5 px-md-5 bg-primary">
            <div className="heading-section heading-section-white ftco-animate mb-5">
              <span className="subheading">Request A Quote</span>
              <h2 className="mb-4">Request A Quote</h2>
              <p>
                "Education is not the filling of a pail, but the lighting of a fire
                in a child's heart, where curiosity becomes the compass and knowledge the journey."
              </p>
            </div>
            <form onSubmit={handleSubmit} className="appointment-form ftco-animate">
              <div className="d-md-flex">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group ml-md-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required // Make the email field required
                />
              </div>
              <div className="d-md-flex">
                <div className="form-group">
                  <div className="form-field">
                    <div className="select-wrap">
                      <div className="icon">
                        <span className="ion-ios-arrow-down"></span>
                      </div>
                      <select
                        name="course"
                        id=""
                        className="form-control"
                        value={formData.course}
                        onChange={handleChange}
                      >
                        <option value="">Select Your Course</option>
                        <option value="Art Lesson">Art Lesson</option>
                        <option value="Language Lesson">Language Lesson</option>
                        <option value="Music Lesson">Music Lesson</option>
                        <option value="Sports">Sports</option>
                        <option value="Other Services">Other Services</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group ml-md-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="d-md-flex">
                <div className="form-group">
                  <textarea
                    name="message"
                    id=""
                    cols="30"
                    rows="2"
                    className="form-control"
                    placeholder="Message"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="form-group ml-md-4">
                  <input
                    type="submit"
                    value="Request A Quote"
                    className="btn btn-secondary py-3 px-4"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Request;
