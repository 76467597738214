import React, { useState } from "react";
import "./Contact.css";
import Nave from "./Nave"; // Make sure to import Nave

const Contact = () => {
  const [formData, setFormData] = useState({
    studentName: "",
    class: "",
    phone: "",
    email: "",
    tutorPreference: "any",
    timeSlot: "",
    subjects: "",
    address: "",
    heardFrom: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    alert("Form Submitted!");
  };

  return (
    <>
      <Nave /> {/* Place Nave component here */}
      <br/>
      <br/>
      <section className="contact_section">
        <div className="container">
          <div className="heading_container">
            <h2>Contact Us</h2>
            <p>Fill in the form below to get in touch with us!</p>
          </div>
          <div className="form_container">
            <form onSubmit={handleSubmit}>
              <div className="input_box">
                <input
                  type="text"
                  name="studentName"
                  placeholder="Student Name"
                  value={formData.studentName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input_box">
                <input
                  type="text"
                  name="class"
                  placeholder="Class"
                  value={formData.class}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input_box">
                <input
                  type="text"
                  name="phone"
                  placeholder="Phone Number"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input_box">
                <input
                  type="email"
                  name="email"
                  placeholder="Email ID"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input_box">
                <select
                  name="tutorPreference"
                  value={formData.tutorPreference}
                  onChange={handleChange}
                  required
                >
                  <option value="any">Any</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
              <div className="input_box">
                <input
                  type="text"
                  name="timeSlot"
                  placeholder="Preferred Time Slot"
                  value={formData.timeSlot}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input_box">
                <input
                  type="text"
                  name="subjects"
                  placeholder="Subjects"
                  value={formData.subjects}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input_box">
                <textarea
                  name="address"
                  placeholder="Home Address"
                  value={formData.address}
                  onChange={handleChange}
                  rows="3"
                  required
                ></textarea>
              </div>
              <div className="input_box">
                <input
                  type="text"
                  name="heardFrom"
                  placeholder="Where did you hear about us?"
                  value={formData.heardFrom}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="submit_box">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
